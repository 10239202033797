* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

html,
body,
#root {
  height: 100%;
}

:root {

  /* Definição das cores-------------------*/
  --main-background: #EAEAEA;
  --grey: #A6A6A6;
  --blue: #5499e9;
  --blueHover: #2a79d9;
  --white: #ffff;
  --textColor: rgba(255, 255, 255, 0.7);
  --textColorTitlePage: #003F89;
  --backgroundBtn: #89B8EF;
  --backgroundLabel: #f8f9fa;

  /* Definição dos Inputs------------------*/
  --inputFontFamily: 'Roboto';
  --inputFontStyle: normal;
  --inputTextFontSize: 12px;
  --inputLineHeight: 14px;
  --inputFontWeight: 400;
  --inputTextColor: #A6A6A6;

  --inputAndButtonHeight: 41px
}

body {
  font: 14px sans-serif;
  background-color: var(--main-background);

}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  width: auto;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  width: auto;
}

#outlined-name {
  width: 28rem;
}

#outlined-email {
  width: 28rem;
}

#outlined-select-currency {
  width: 26.75rem;
}

.p-toolbar {
  border-radius: 10px !important;
  background-color: white !important;
  background: #f8f9fa !important;
  border: 1px solid #dee2e6 !important;
  padding: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 6px !important;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}

.p-datatable .p-paginator-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.p-datatable .p-datatable-header {
  background: white !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}