.container-dashboard{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* background-color: #f8f8f8; */
  border-radius: 5px;
  padding: .8em;
  margin-bottom: 1em;
}

.container-dashboard input, textarea, select{
  padding: .7em;
  border:1;
  border-radius: 10px;
  max-width: 600px;
}

.container-dashboard h1 {
border-bottom: none;
}


.container-dashboard input:disabled{
  cursor: not-allowed;
}

.container-dashboard label {
  margin-bottom: 0.5em;
  font-size: 1.2rem;
}


.dados-tabela{
height: 800px; 
width: 100%;
margin-top: 40px;
}


.header-importacao{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btnCalcular{
  padding: 8px 20px;
  background-color: #f2f2f2;
  border:1px solid #121212;
  border-radius: 5px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnDownload{
  padding: 8px 20px;
  background-color: #f2f2f2;
  border:1px solid #121212;
  border-radius: 5px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-box{
  font-size: 1em;
  background: white;
  width: 350px;
  outline: none; 
}

::-webkit-file-upload-button{
  color: white;
  background: #89b8ef;
  padding:5px;
  border:none;
  outline: none;

}

::-webkit-file-upload-button:hover{
  background: #78a1d1;
}

.btnDownload:hover{
  background-color: #fff;
}


.text-graph {
  font-size: 1.2rem;
  color: #000000;
  text-align: center;
}

tr{
  text-align: center;
}

.btnCalcular:hover{
  background-color: #fff;
}


.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 100px;
  }

  .wrapper p {
    margin-bottom: 0.5em;
  font-size: 1.4em;
  }
  
  .charts{
    display: flex;
    width: 200px;
  }

  .btnCharts{
    display: flex;
  }

  .btnCharts > div{
    width: 500px;
    justify-content: center;
    display: flex;
  }


  .btnArea{
    display: flex;
  align-content: center;
  flex-wrap: wrap;
  }

  .calcular{
    display: flex;
  align-items: center;
  }

  .charts{
    display: flex;
  }

  .btn-importar{
    width: 100px;
    border-radius: 0;
    background-color: var(--backgroundBtn);
    color: var(--white);
  }

  .tipo-arquivo-label{
    background-color: #f8f8f8;
    margin: 0 .5rem 0 0;
    width: auto;
  }

  .tipo-arquivo-label{
    padding-right: 1rem;
  }

  .header-importacao-observacoes{
    background-color: #f8f8f8;
    height: 150px;
    
  }

  .p-toolbar-group-right{
    justify-content: end;
  }

  .button-right{
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .button-right button{
    margin-right: 0px !important;
  }

  .p-dialog .p-dialog-content{
    padding: 0 1rem 2rem 1rem;
  }

  #outlined-select-currency-empresa {
    width: 10rem;
    height: 25px;
  }

  .download-ged:hover{
    cursor: pointer;
  }

