.container {
    display: flex;
    background-color: #f8f8f8;
    /* border-radius: 1%; */
    padding: .8em;
    align-items: center;
    margin-bottom: 1em;
    height: fit-content;
}

.container-user {
    background-color: #f8f8f8;
    border-radius: 30px;
    padding: 0.8em;
    align-items: center;
    margin-bottom: 1em;
    height: fit-content !important;
    margin-left: 80px;
}

.container-user-active {
    margin-left: 280px;
}

.label-avatar {
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

}

.btn_cadastrar {
    height: 35px;
    border: 0;
    border-radius: 7px;
    background-color: #89b8ef;
    color: #fff;
    font-size: 1.3em;
    width: 120px !important;
}

.label-avatar input {
    display: none;
}

.label-avatar span {
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
}

.label-avatar span:hover {
    opacity: 1;
    transform: scale(1.4);
}

.form-cadastro {
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-user {
    margin-top: 0.5em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-color: white;
    height: fit-content;
    border-radius: 5%;
}

.form-container .form-box {
    margin-bottom: 15px;
}

.form-container .form-box div {
    margin-right: .5rem;
}

.form-container .form-box .details {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
}

.form-container .form-box input {
    height: 40px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    font-size: 16px;
    border-bottom-width: 2px;
    transition: all 0.3 ease;
}

.form-container .form-box input:focus {
    border-color: #01346b;
}


.form-profile img {
    margin-bottom: 1em;
    border-radius: 50%;
    object-fit: cover;
}


.btn-submit {
    height: 35px;
    border: 0;
    border-radius: 7px;
    background-color: #89b8ef;
    color: #fff;
    font-size: 1.3em;
    width: 120px;
    transition: all 0.3 ease;
}

.btn-submit:hover {
    background-color: #78a1d1;
}

h1 {
    text-align: center;
    margin-bottom: 0.5em;
    color: #181c2e;
}

#simple-tabpanel-1 {
    width: 100%;
}


.card {
    width: 100%;
}

#field-autocomplete{
    width: 100%;
    margin-top: 1.5rem;
    width: 420px;
    overflow-y: hidden;
    overflow-x: hidden;
    cursor: pointer;    
}

#field-autocomplete span:hover{
    border: unset;
}

.p-dialog-content{
    overflow-x: hidden;
}

#field-autocomplete span{
    width: auto;
    height: 53.13px;
}

#field-autocomplete span ul{
   border-color:#bdbdbd;
   -webkit-tap-highlight-color: transparent;
}

#field-autocomplete span ul:hover{
    border: 1px solid black;
    cursor: pointer;
}

.label-base-client{
    display: flex;
    align-items: center;
    position: relative;
    top: 9px!important;
    z-index: 5;
    background: white;
    width: -moz-fit-content!important;
    width: fit-content!important;
    height: auto!important;
    margin-left: 11px!important;
    padding-left: 5px!important;
    padding-right: 5px!important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 100;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    font-size: 11px;

}