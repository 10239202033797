html {
    font-size: 14px;
}

.card {
    margin-bottom: 2rem;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1)
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3)
    }

    100% {
        background-color: rgba(165, 165, 165, 0.1)
    }
}

.customer-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.customer-badge.status-qualified {
    background-color: #C8E6C9;
    color: #256029;
}

.customer-badge.status-unqualified {
    background-color: #FFCDD2;
    color: #C63737;
}

.customer-badge.status-negotiation {
    background-color: #FEEDAF;
    color: #8A5340;
}

.customer-badge.status-new {
    background-color: #B3E5FC;
    color: #23547B;
}

.customer-badge.status-renewal {
    background-color: #ECCFFF;
    color: #694382;
}

.customer-badge.status-proposal {
    background-color: #FFD8B2;
    color: #805B36;
}

.product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.product-badge.status-instock {
    background: #C8E6C9;
    color: #256029;
}

.product-badge.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
}

.product-badge.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
}

.order-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.order-badge.order-delivered {
    background: #C8E6C9;
    color: #256029;
}

.order-badge.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
}

.order-badge.order-pending {
    background: #FEEDAF;
    color: #8A5340;
}

.order-badge.order-returned {
    background: #ECCFFF;
    color: #694382;
}

.image-text {
    vertical-align: middle;
    margin-left: .5rem;
}

.p-multiselect-representative-option {
    display: inline-block;
    vertical-align: middle;
}

.p-multiselect-representative-option img {
    vertical-align: middle;
    width: 24px;
}

.p-multiselect-representative-option span {
    margin-top: .125rem;
}

.p-column-filter {
    width: 100%;
}

.country-item {
    display: flex;
    align-items: center;
}

.country-item img.flag {
    width: 18px;
    margin-right: .5rem;
}

.flag {
    vertical-align: middle;
}

span.flag {
    width: 44px;
    height: 30px;
    display: inline-block;
}

img.flag {
    width: 30px
}

.odd {
    background-color: rgb(238, 238, 238);
}

.p-toolbar-group-right {
    width: 50%;
}

.p-toolbar-group-right button {
    margin: 0 .5rem;
}

.p-datatable-resizable>.p-datatable-wrapper {
    overflow-x: visible !important;
}

.p-fluid .p-button {
    width: auto;
}

.p-toolbar-group-left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px 8px;
}

.arqModelo{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
}

#outlined-select-currency-filtros {
    width: 10rem;
    height: 25px;
}

#grid-cadastro-usuario {
    border-radius: 6px;
}


.p-datatable .p-datatable-header tr,
th {
    background: white !important
}

.data-Grid-Pro {
    background-color: white;
    border-radius: 10px !important;
}

#outlined-select-currency-filtros-label{
    background-color: var(--backgroundLabel);
    width: auto;
    padding: 0 5px 0 0;  
}

#outlined-select-currency-empresa-label{
    background-color: var(--backgroundLabel);
    width: auto;
    padding: 0 5px 0 0;  
}