.container-center{
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.login {
    width: 402px;
    height: 442px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo-area {
    display: flex;
    justify-content: center;
    width: 100%;
}

.logo-area img {
    width: 299px;
    height: 145px;
}

.form-login-info{
    width: 402px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--blue);
    border: none;
    border-radius: 30px;
}

.wrap-input100{
    margin: 0.5rem;
    border: 1px solid var(--grey);
}

.wrap-input100 .input100{
    font-family: var(--inputFontFamily);
    font-style: var(--inputFontStyle);
    font-weight: var(--inputFontWeight);
    font-size: var(--inputTextFontSize);
    line-height: var(--inputLineHeight);
    color: var(--inputTextColor);
    min-width: 276px;
    max-width: 200px;
    height: 41px;
    background: var(--white);
    padding-left: 1rem;
}

.form-login-info button{
    border: none;
    text-transform:uppercase;
    color: var(--blue);
    letter-spacing: 3px;
    font-weight: bold;
    width: 265px;
}

.login a {
    margin:1.5em 0;
    color: #000;
    cursor: pointer;
}

.login a.forgot{
    font-size: 12px;
    margin: unset;
    color: white;
}

.login a.forgot:hover{
    color: rgb(214, 208, 208);
}
