
.container-profile{
    /* background-color: #f8f8f8;
    border-radius: 30px;
    padding: .8em;
    align-items: center;
    margin-bottom: 1em;
    height: 92.5vh; */
  }
  
  .label-avatar{
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;  
  }
  
  .label-avatar input{
    display: none;
  }
  
  .label-avatar span{
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
  }
  
  .label-avatar span:hover{
    opacity: 1;
    transform: scale(1.4);
  }

  .form-container{
      width:600px;
     margin-top: 25px;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
  }

  #form-profile{
    margin-top: 0.5em;
    width: 683px;
    height: 391px;
    display: flex;
    background-color: white;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.07);
    border-radius: 26px;

  }
  
  .form-container .form-box{
    margin-bottom: 2rem;
  }

  .form-container .form-box div{
    margin-right: .5rem;
  }

  .form-container .form-box .details{
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .form-container .form-box input{
    height: 40px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    font-size: 16px;
    border-bottom-width: 2px;
    transition: all 0.3 ease;
  }

  .form-container .form-box input:focus{
    border-color: #01346b;
  }

  
  #form-profile img{
    margin-bottom: 1em;
    border-radius: 50%;
    object-fit: cover;
  }


  .form-container .form-box input{
    height: 1rem;
  }
  .Mui-focused{
    color: unset;
    border-color: unset;
  }


  #form-profile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .profile-avatar{
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .profile-info{
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .label-p-avatar {
    width: 400px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 280px;
  }
  
  .label-p-avatar input{
    display: none;
  }
  
  .label-p-avatar span{
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
  }
  
  .label-p-avatar span:hover{
    opacity: 1;
    transform: scale(1.4);
  }

  .form-profile button{
    margin-left: unset!important;
    background: #0072F8!important;
    border-radius: 25px;
    color: white;
    width: 85%;
    height: 35px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.1em;
    height: 55px;
  }

  #outlined-profile-info{
    width: 30rem;
    min-height: 53px;
    height: 53px;
    margin-top: 0.5rem;
  }

  #outlined-profile-info ul{
    width: 100%;
    height: auto;
  }

  #outlined-profile-info ul li{
    width: 25%;
    margin: 0.5rem;

  }

  .avatar-profi{
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 15px;
    width: auto;
    height: 15px;
    margin: 0.5rem;
    text-align: center;
  }

  .avatar-name{
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 17px;
    width: auto;
    /* identical to box height */
    text-align: center;
    height: 30px;
  }

  .label-p-avatar img{
    width: 61%;
    height: 90%;
  }

  .perfil-botoes{
    display: flex;
    width: 84%;
    /* border: 1px solid; */
  }

  .perfil-botoes button{
    margin: 0 5px;
  }