.sidebar {
  width: 80px;
  background-color: var(--blue);
  position: fixed;
  height: 100%;
  transition: 1.5s;
}

.sidebar-active {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-content: center;
  transition: 1.5s;
  overflow: auto;
}

.pact-logo {
  width: 130px;
  height: 63px;
}

.sidebar-icons-active {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0 1rem;
  justify-content: flex-start;
  align-content: center;
  transition: 1.5s;
}

.sidebar-icons-active img{
  width: 25px;
  height: 23px;
}

.sidebar-icons-active a {
  width: 40px;
  border: 1px solid black;
}


.sidebar-logo {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}

.sidebar-icons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-bottom: 1rem;
  padding: 1rem 0;
  height: auto;
}

.signOut {
  margin-top: 4rem;
}

.signOut img, span {
  cursor: pointer;
}

.content {
  margin-left: 80px;
  padding: 1px 16px;
  transition: 1.5s;
}

.content-active {
  margin-left: 280px;
  padding: 1px 16px;
  transition: 1.5s;
}

.header {
  display: flex;
  height: 60px;
  background-color: var(--blue);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}


.header div {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  margin: 0 5px 0 5px;
}

.menu-configuracoes{
  margin-right: 25px!important; 
  width: 35px!important;
  height: 35px!important;
  /* border: 1px solid; */
}

.menu-configuracoes svg{
  width: 30px;
  height: 30px;
}

.menu-configuracoes:hover{
  cursor: pointer;
}

.menu-configuracoes-active{
  display: flex!important;
  flex-direction: column;
  background-color: #fff;
  width: 500px!important;
  height: 440px!important;
  position: absolute;
  top: 50px;
  right: 20px;
  border-radius: 10px!important;
  z-index: 1;
  border: 1px solid rgba(201, 197, 197, 0.486);
  align-content: flex-start;
  align-items: flex-start!important;
  /* padding: 0 20px; */
}

.menu-configuracoes-active a{
  cursor: pointer;
  /* border: 1px solid; */
  width: 100%;
  height: 50px;
  display: flex;
  margin: 0.2rem 0;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Nunito', sans-serif;
}

.menu-configuracoes-active a:hover{
  background-color: var(--main-background)
  /* border-radius: 10px; */
  /* padding: 20px 10px; */

}

.menu-configuracoes-active a span{
  color: black;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  align-items: flex-start;
  justify-content: space-around;
  line-height: unset!important;
  text-transform: unset!important;
  font-weight: 600;
  font-family: 'Nunito', sans-serif!important;
}

.menu-configuracoes-active a svg{
  /* padding-left: 10px; */
  margin-left: 20px;
}

.menu-configuracoes-active span{
  color: black!important;
  padding-left: 10px;
  font-weight: 800;
}



.header img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.header span {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 15px;
  color: var(--white);
}

.sidebar-icons:hover {
  background-color: var(--blueHover);
  cursor: pointer;
}

.sidebar-icons-active:hover {
  background-color: var(--blueHover);
  cursor: pointer;
}

#humburguer-active{
  width: 27.95px;
  height: 23px;
}

#file-active{
  width: 27.95px;
  height: 23px;
}

#config-active{
  width: 27.95px;
  height: 23px;
}

.icons-size{
  width: 25px;
  height: 23px;
}

.selected{
  background-color: var(--blueHover);
}


@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  div.content {
    margin-left: 0;
  }

  .sidebar a {
    float: left;
  }

  .sidebar div {
    display: none;
  }

  .sidebar a svg {
    display: none;
  }
}

.logout-btn-menu {
  cursor: pointer;
}

.sub-menu-itens-active{
  color: rgba(255, 255, 255, 0.7);
  padding: 1rem 1.5rem;
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  font-family: 'Nunito', sans-serif;
  justify-content: flex-start;
}

.sub-menu-itens-active img{
  margin-right: .5rem;
  width: 25px;
  height: 23px;
}

.sub-menu-itens-active svg{
  margin-right: .5rem;
  width: 25px;
  height: 23px;
}

.sub-menu-itens-active:hover{
  background-color: var(--blueHover);
  color: rgba(255, 255, 255, 0.7);
  
}

.btn-lateral-active {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  border: none;
  background: transparent;
  width: 80px;
  border-radius: 0;
  display: flex;
  align-items: flex-start;
}

.sidebar-icons-active span {
  padding-left: 1rem;
  font-family: 'Nunito', sans-serif;
  color: var(--textColor);
  font-size: 14px;
  line-height: 1.7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.sidebar-icons-active svg{
  width: 25px;
  height: 23px;
}

.sidebar-icons-active span a {
  color: rgba(255, 255, 255, 0.7);
}

.btn-lateral {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  border: none;
  background: transparent;
  width: 100%;
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }

  .sidebar a svg {
    display: none;
  }
}

.pact-logo-close{
  width: 100px;
  height: auto;
}