.card-component{
    display: flex;
    align-content: flex-end;
    width: 22rem;
    height: 8rem;
    margin: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.35rem;
    background-clip: initial;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    padding: 0 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font: 14px sans-serif;
    /* box-shadow: -2px 0px 3px 0px rgb(74, 114, 223); */
    box-shadow: -3px 0px 0px 0px rgb(74, 114, 223);;
}

.card-component span{
    color: black;
    font-size: 18px;
    align-items: flex-end;
    padding: 0.5rem 0;
    font-weight: 500;
}

.card-component p{
    text-align: start;
    font: 16px sans-serif;
    color: #5a5c69;
    padding: 0.5rem 0;
}

.card-button{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
}

.card-button svg{
    /* color: green; */
    color: var(--blue);
    cursor: pointer;
}

.card-button svg:hover{
    color: rgb(11, 70, 230);
}
