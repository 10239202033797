.container-profile {
  /* background-color: #f8f8f8;
  border-radius: 30px;
  padding: .8em;
  align-items: center;
  margin-bottom: 1em;
  height: 92.5vh; */
}

.label-avatar {
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

}

.label-avatar input {
  display: none;
}

.label-avatar span {
  z-index: 99;
  position: absolute;
  opacity: 0.7;
  transition: all .5s;
}

.label-avatar span:hover {
  opacity: 1;
  transform: scale(1.4);
}

.form-container {
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-profile {
  margin-top: 0.5em;
  width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: white;
  height: 53rem;
}

.form-profile button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: -34rem;
  background-color: #01346b;
  border-radius: 5rem;
  height: 35px;
}

.form-container .form-box {
  margin-bottom: 2rem;
}

.form-container .form-box div {
  margin-right: .5rem;
}

.form-container .form-box .details {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-container .form-box input {
  height: 40px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3 ease;
}

.form-container .form-box input:focus {
  border-color: #01346b;
}


.form-profile img {
  margin-bottom: 1em;
  border-radius: 50%;
  object-fit: cover;
}


.btn-submit {
  height: 35px;
  border: 0;
  border-radius: 7px;
  background-color: #01346b;
  color: #fff;
  font-size: 1.3em;
  width: 120px;
  transition: all 0.3 ease;
}

.form-container .form-box input {
  height: 1rem;
}

.Mui-focused {
  color: unset;
  border-color: unset;
}

/* DataTableDemo.css ------------------------------------------------------------------------------------------------------------------------*/

.datatable-crud-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* revisar css --------------------------*/
.p-dialog-footer button{
  padding: 1.5rem 0;
  margin: 0;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.1rem 0 0.3rem;
  width: auto;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1rem;
}

/* revisar css --------------------------*/
@media screen and (max-width: 960px) {
  .datatable-crud-demo .table-header {
      align-items: flex-start;
  }
}
.datatable-crud-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.datatable-crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  .datatable-crud-demo .p-toolbar {
      flex-wrap: wrap;
  }
  .datatable-crud-demo .p-toolbar .p-button {
      margin-bottom: 0.25rem;
  }
  .datatable-crud-demo .table-header {
      flex-direction: column;
  }
  .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
      width: 100%;
  }
}
              